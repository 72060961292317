import moment from 'moment-mini-ts';
import { timezoneAbbr } from '../../../legacy-utils/tz-abbr';
import { numFormatter } from '../../../legacy-utils/number';
import { DEFAULT_CALENDAR_FORMATS } from '../../../components/UIElements/HdCalendar/constants';

export interface RateLimitViolation {
  ok?: boolean;
  window?: RateLimitWindow;
  global_violation?: ViolationStage;
  local_violations?: { [key: string]: ViolationStage };
}

export interface RateLimitWindow {
  begin: number;
  end: number;
}

export interface ViolationStage {
  limit: number;
  used: number;
  violated_on: number;
}

export function getRateLimitViolationTitle(violation: RateLimitViolation) {
  const ingestionLimitTitle = 'Pipelines Have Stopped Ingesting Events!';

  if (violation.global_violation) {
    return ingestionLimitTitle;
  } else if (violation.local_violations) {
    if (violation.local_violations['ingestion']) {
      return ingestionLimitTitle;
    } else if (violation.local_violations['replayed_transformer']) {
      return 'Pipelines Have Stopped Replaying Failed Events!';
    }
  }
}

export function getRateLimitViolationMsg(violation: RateLimitViolation) {
  if (!violation.window) {
    return;
  }

  const windowEndString: string = moment(violation.window.end)
    .calendar(null,
      DEFAULT_CALENDAR_FORMATS
    ) + ' (' + timezoneAbbr(new Date(violation.window.end)) + ')';

  let msg: string;

  const windowMins: number = Math.round(
    (violation.window.end - violation.window.begin) / 60000
  );

  if (violation.global_violation) {
    msg = getGlobalViolationMessage(
      violation.global_violation.limit,
      windowMins,
      windowEndString
    );
  } else if (violation.local_violations) {
    if (violation.local_violations['ingestion']) {
      msg = getIngestionViolationMessage(
        violation.local_violations['ingestion'].limit,
        windowMins,
        windowEndString
      );
    } else if (violation.local_violations['replayed_transformer']) {
      msg = getReplayQViolationMessage(
        violation.local_violations['replayed_transformer'].limit,
        windowMins,
        windowEndString
      );
    }
  }

  return msg;
}

export function MockRateLimitViolation(): RateLimitViolation {
  return {
    ok: false,
    window: {
      begin: 1543560300000,
      end: 1544426536194
    },
    global_violation: {
      limit: 100,
      used: 10,
      violated_on: 1543560900110
    },
    local_violations: {
      ingestion: {
        limit: 60,
        used: 10,
        violated_on: 1543560900110
      },
      replayed_transformer: {
        limit: 70,
        used: 2243,
        violated_on: 1543560900110
      }
    }
  };
}

export function getGlobalViolationMessage(limit: number, window: number, windowEnd: string) {
  return `Pipelines have exhausted their quota of ${ numFormatter(limit) } Events / ${ window } mins. Ingestion will resume at ${ windowEnd }.`;
}

export function getIngestionViolationMessage(limit: number, window: number, windowEnd: string) {
  return `Pipelines have exhausted their quota of ${ numFormatter(limit) } Events / ${ window } mins. Ingestion will resume at ${ windowEnd }.`;
}

export function getReplayQViolationMessage(limit: number, window: number, windowEnd: string) {
  return `Pipelines have exhausted their replay quota of ${ numFormatter(limit) } Events / ${ window } mins. Hevo will resume replaying Events at ${ windowEnd }.`;
}
