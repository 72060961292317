import moment from 'moment-mini-ts';
import { interval } from 'rxjs';
import { exhaustMap, map, startWith } from 'rxjs/operators';
import { BACKGROUND_NETWORK_REQ_UI_OPTIONS } from '../constants';
import { NetworkRequestOptions } from '../models/request';
import { RxRequestService } from './rx-request.service';
import { TeamService } from './team.service';
import { UserService } from './user.service';


const NPS_POLL_INTERVAL = 1800000; // 30 mins

export class NPSService {
  static deps = [
    RxRequestService,
    UserService,
    TeamService
  ];

  npsRating = 0;

  constructor(
    private _rxRequestService: RxRequestService,
    private _userService: UserService,
    private _teamService: TeamService
  ) {
  }

  npsStatus$ = interval(NPS_POLL_INTERVAL)
    .pipe(
      startWith(0),
      exhaustMap(() => this._getNpsStatus()),
      map((res: any) => res.data)
    );

  private _getNpsStatus() {
    return this._teamService.getNpsStatus();
  }

  setNpsScore(rating: number) {
    this.npsRating = rating;
    return rating;
  }

  getNpsScore() {
    return this.npsRating;
  }

  updateRating(feedbackId: string, rating: number, comment: string, reason?: string) {
    return this._teamService.updateNPSRating(feedbackId, rating, comment, reason);
  }

  // This method writes to google sheets using zapier
  remind3rdPartyRatingLater(rating) {
    const zapierPostHookUrl = 'https://hooks.zapier.com/hooks/catch/1862759/ozpc9yj/';

    const email = this._userService.getUser().email;
    const reqOptions: NetworkRequestOptions = {
      uiOptions: BACKGROUND_NETWORK_REQ_UI_OPTIONS,
      networkOptions: {
        headers: {
          'Anonymous': 'True'
        }
      }
    };
    // Zapier does not accept Content-Type application/json so encoding in form.
    const formData = new FormData();
    formData.append('email', email);
    formData.append('rating', rating);
    formData.append('remind_me_later', 'YES');
    formData.append('date', moment().format('YYYY-MM-DD hh:mm:ss'));

    return this._rxRequestService.post(zapierPostHookUrl, reqOptions, formData);
  }

  refuseRating(feedbackId: string) {
    return this._teamService.refuseRating(feedbackId);
  }
}
