import moment from 'moment-mini-ts';
import { BehaviorSubject, interval, Observable, Subscriber } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { execIfDefined } from '../../../legacy-utils/exec-if-defined';
import { BillingProvider } from '../../payment/models/payment';
import {
  ON_CALL_SUPPORT_PERSON_IMAGE_SHUFFLE_INTERVAL,
  ON_CALL_SUPPORT_ROSTER,
  ON_CALL_SUPPORT_STATUS_CHECK_INTERVAL,
  SUPPORT_PERSON_IMAGES,
  SupportService,
  WeekDays
} from '../models/support';
import { User } from '../models/user';

declare var Intercom: any;

declare var __DEPLOYMENT_ENV__: any;
declare var __INTERCOM_APP_ID__: string;

interface PlanDetails {
  billing_provider_type: BillingProvider;
  monthly_spend: number;
  plan: string;
}

export class IntercomService implements SupportService {
  user: User;
  planDetails: PlanDetails;
  private _liveChatIsOpen$ = new BehaviorSubject(false);
  private _onCallSupportPersonImage = '';

  constructor() {
  }

  supportLink = 'mailto:zgbmsle7@intercom-mail.com';

  unreadCount$ =  new Observable(
    (subscriber: Subscriber<number>) => {
      execIfDefined('Intercom', () => {
        Intercom('onUnreadCountChange', (unreadCount: number) => {
          subscriber.next(unreadCount);
        });
      });
    }
  );

  isOnCallSupportAvailable$ = interval(ON_CALL_SUPPORT_STATUS_CHECK_INTERVAL).pipe(
    startWith(0),
    map(() => this._isOnCallSupportAvailable())
  );

  onCallSupportPersonImage$ = interval(ON_CALL_SUPPORT_PERSON_IMAGE_SHUFFLE_INTERVAL).pipe(
    startWith(0),
    tap(() => this._setOnCallSupportPersonImage()),
    map(() => this._onCallSupportPersonImage)
  );

  show(body?: string) {
    if (typeof __INTERCOM_APP_ID__ === 'undefined' || !__INTERCOM_APP_ID__) {
      window.open(
        this.supportLink,
        '_blank'
      );

      return;
    }

    this._showIntercom(body);
  }

  private _showIntercom(body?: string) {
    execIfDefined('Intercom', () => {
      this._liveChatIsOpen$.next(true);

      if (body) {
        Intercom('showNewMessage', body);
        return;
      }

      Intercom('show');
    });
  }

  hide() {
    execIfDefined('Intercom', () => {
      this._liveChatIsOpen$.next(false);
      Intercom('hide');
    });
  }

  setCurrentUser(user: User) {
    this.user = user;
    this._setUserInIntercom();
  }

  private _setUserInIntercom() {
    execIfDefined('Intercom', () => {
      Intercom('boot', this.buildIntercomPayload());
    });
  }

  buildIntercomPayload() {
    if (!this.user) {
      return undefined;
    }

    const user = this.user;

    const payload = {
      email: user.email
    };

    if (user.name !== user.email) {
      payload['name'] = user.name;
    }

    if (user.profileImage) {
      payload['avatar'] = {
        type: 'avatar',
        image_url: user.profileImage
      };
    }

    if (user.teamId && user.teamName) {
      payload['company'] = {
        id: user.clusterId + ':' + user.teamId,
        website: `https://${user.teamName}`,
        env: user.clusterId
      };

      if (this.planDetails) {
        payload['company'] = {
          ...payload['company'],
          ...this.planDetails
        };
      }
    }

    return payload;
  }

  reset() {
    this.user = undefined;

    execIfDefined('Intercom', () => {
      try {
        Intercom('shutdown');
        Intercom('boot');
      } catch (e) {
        // Calling intercom shutdown if intercom is not booted results in exception.
      }
    });
  }

  hideDefaultLauncher() {
    execIfDefined('Intercom', () => {
      Intercom('update', {
        hide_default_launcher: true,
        vertical_padding: 20,
        ...this.buildIntercomPayload()
      });
    });
  }

  showDefaultLauncher() {
    execIfDefined('Intercom', () => {
      Intercom('update', {
        hide_default_launcher: false,
        vertical_padding: 60,
        ...this.buildIntercomPayload()
      });
    });
  }

  refresh() {
    execIfDefined('Intercom', () => {
      Intercom('update', this.buildIntercomPayload());
    });
  }

  setPlanDetails(details: PlanDetails) {
    this.planDetails = details;
    Intercom('update', this.buildIntercomPayload());
  }

  isChatHidden() {
    const chatDisabledTeams = [ {
      env: 'us',
      name: 'nextsphere.com',
      id: 53
    } ];

    return chatDisabledTeams.findIndex((team) => {
      return team.env === __DEPLOYMENT_ENV__ && team.id === this.user.teamId;
    }) >= 0;
  }

  private _isOnCallSupportAvailable() {
    /**
     * Roster is predefined for support and is in IST.
     * */
    const timeFormat = 'HH:mm:ss';

    const currentLocalTime = new Date();
    const ISTLocaleString = currentLocalTime.toLocaleString('en', { timeZone: 'Asia/Kolkata'});
    const utcMoment = moment.utc(ISTLocaleString);

    const time = utcMoment.format(timeFormat);
    const day = utcMoment.format('dddd') as WeekDays;

    const onCallRoster = ON_CALL_SUPPORT_ROSTER[day];

    // * As currently available 24/7, might change later
    return true;

    if (onCallRoster) {
      return onCallRoster.some((schedule) => {
        const startTimeMoment = moment(schedule.startTime, timeFormat);
        const endTimeMoment = moment(schedule.endTime, timeFormat);

        return moment(time, timeFormat).isBetween(startTimeMoment, endTimeMoment);
      });
    }
    return false;
  }

  private _setOnCallSupportPersonImage() {
    if (!this._onCallSupportPersonImage) {
      this._onCallSupportPersonImage = SUPPORT_PERSON_IMAGES[0];
      return SUPPORT_PERSON_IMAGES[0];
    }

    const currentImageIndex = SUPPORT_PERSON_IMAGES.findIndex(
      (image) => this._onCallSupportPersonImage === image
    );
    const nextImageIndex = (currentImageIndex + 1) % SUPPORT_PERSON_IMAGES.length;
    this._onCallSupportPersonImage = SUPPORT_PERSON_IMAGES[nextImageIndex];
  }

  isLiveChatOpen() {
    return this._liveChatIsOpen$.getValue();
  }
}
