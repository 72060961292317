import React from 'react';
import ReactDOM from 'react-dom';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';

import { App } from './react/containers/App';
import { AnsibleBoolean } from './react/containers/core/models/ansible-config';

declare const __METICULOUS_RECORDER_CONFIG__: { enabled: AnsibleBoolean; projectId?: string };

async function startMeticulousRecorder() {
  if (
    typeof __METICULOUS_RECORDER_CONFIG__ === 'undefined' ||
    !__METICULOUS_RECORDER_CONFIG__ ||
    __METICULOUS_RECORDER_CONFIG__.enabled !== AnsibleBoolean.TRUE
  ) {
    return;
  }

  await tryLoadAndStartRecorder({
    projectId: __METICULOUS_RECORDER_CONFIG__.projectId as string,
    isProduction: false
  });
}

async function bootstrapApp() {
  try {
    await startMeticulousRecorder();
  } catch (err) {
    console.log(err);
  }

  ReactDOM.render(
    React.createElement(App),
    // tslint:disable-next-line:no-non-null-assertion
    document.getElementById('root')!
  );
}

bootstrapApp();
